import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"
import DottedLineHorizontal from "../components/DottedLineHorizontal"
import { Link } from "gatsby"

const active = {
  title: "Preise"
}

const Preise = () =>
  <DefaultLayout image="/assets/images/IMG_6972.jpeg" active={active}>
    <TwoThirdsGrid textRight>
      <div>
        <HeadlineWithSub>
          <h1>Preise</h1>
        </HeadlineWithSub>
      </div>
      <Text>
        <p>
          <strong>
            Mal- und Gestaltungstherapie für Kinder, Jugendliche und Erwachsene<br />
          </strong>
          Fr. 130.—pro Stunde<br />
        </p>
        <p>
          <strong>
            Coaching und Beratung<br />
          </strong>
          Fr. 150.—pro Stunde<br /><br />
          Zusätzliche Aufwendungen wie Telefongespräche, Berichte, Mails, usw. werden nach effektivem Zeitaufwand auf
          der Basis des Stundenhonorars berechnet.
        </p>

        <p>
          <strong>
            Stresscoaching und Burnoutprävention – Package 3 Monate<br />
          </strong>
          Fr. 2100.—<br />
        </p>
        <p>
          <strong>
            Stresscoaching und Burnoutprävention – Package 6 Monate<br />
          </strong>
          Fr. 3900.—<br />
        </p>
        <p>
          <strong>
            Kurzzeitcoaching<br />
          </strong>
          2 h Fr. 290.— <br /><br />
          Die Leistungen für Coaching sind nicht krankenkassenanerkannt.
        </p>
        <p>
          <strong>
            Kurse<br />
          </strong>
          Nach Beschreibung
        </p>

        <DottedLineHorizontal />

        <p>
          <strong>Terminabsagen</strong><br />
          Die Termine werden in gegenseitiger Absprache festgelegt und sind beidseitig verbindlich. Bitte denken Sie
          daran, im Verhinderungsfall mindestens 24h vor Ihrem vereinbarten Termin telefonisch Bescheid zu geben. Für
          verspätete Abmeldungen wird ein Stundenhonorar in Rechnung gestellt.
        </p>
        <p>
          <strong>Kursabsagen</strong><br />
          Sie gelten als angemeldet, sobald das Kursgeld bezahlt ist. Bis 10 Tage vor Kursbeginn können sie sich
          kostenlos abmelden, bis 5 Tage vor Kursbeginn werden ihnen 50 % vom Kursgeld zurückerstattet. Bei späterer
          Abmeldung tragen sie die gesamten Kosten. Können sie ein Arztzeugnis vorweisen, fällt lediglich ein
          Bearbeitungsaufwand von Fr. 30.—an.
        </p>
        <p>
          <strong>Reisespesen</strong><br />
          Findet der Termin bei dem/den Klienten vor Ort statt, werden die Reisespesen (öV 1. Klasse Halbtax) vom
          Auftraggeber getragen.
        </p>
        <p>
          <strong>Die vollständigen Allgemeinen Geschäftsbedingungen siehe unter <Link to="/agb">AGB</Link></strong>
        </p>
      </Text>
    </TwoThirdsGrid>
  </DefaultLayout>

export default Preise